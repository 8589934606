<template>
  <div class="home_main">
    <!-- swiper -->
    <div class="swiper">
      <el-carousel class="home_carousel">
        <el-carousel-item
          v-for="(item, index) in carouselItems"
          :key="index"
          class="swiper-item"
        >
          <img :src="`${item.imageUrl}`" class="swiper-pic" />
          <div class="swiper-btn white-hover" @click="toContactUs()">
            CONTACT FORM
            <span class="swiper-text">></span>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="part">
      <div class="part-title">
        <div class="part-line"></div>
        <div class="part-name">Where We Came From</div>
      </div>
      <div class="part-text">
        From humble beginnings, we've worked incredibly hard to establish
        ourselves as a trade blinds market leader. All products are made to
        measure in the China, manufactured in our dedicated facility by our
        trusted team of experts.
      </div>
      <div class="part-btn black-hover" @click="toOurStory()">
        OUR STORY<span class="swiper-text">></span>
      </div>
      <div class="part-line-x"></div>
      <div class="part-list">
        <div
          class="part-pro-item"
          v-for="(v, i) in proList"
          :key="i"
          @click="toProDetails(v.productId)"
        >
          <img :src="`${ossPrefix}${v.icon}`" class="part-pro-img" />
          <span class="part-pro-text">{{ v.productName }}</span>
        </div>
      </div>
    </div>
    <div class="home-content">
      <div class="home-content-top">
        <div class="home-content-liney"></div>
        <div class="home-content-title">Our Products</div>
      </div>
      <div class="home-content-text">
        We offer a comprehensive range of indoor furniture, tailored to the
        specific needs of Ningbo, China. This includes Venetian blinds, vertical
        blinds, roller blinds, and roof blinds, as well as our luxurious hand
        sewn Roman blinds and curtains. Our wooden blinds are also particularly
        popular, made of the highest quality solid wood and delivered within 4-5
        business days - faster than any competitor.
      </div>
      <div class="home-img-list">
        <div class="home-img-list1">
          <div class="home-item" v-for="(v, i) in proList1" :key="i">
            <img
              :src="`${ossPrefix}${v.cover}`"
              class="home-img"
              @click="toProDetails(v.productId)"
            />
            <div class="home-img-text" :class="getContentColor1(i)">
              {{ v.productName }}
            </div>
            <div
              v-if="v.pdfFileUrl"
              class="home-img-btn black-hover-border"
              @click="toDownload(v.productId)"
            >
              Download Producet PDF <span class="swiper-text">></span>
            </div>
          </div>
        </div>
        <div class="home-img-list2">
          <div class="home-item" v-for="(v, i) in proList2" :key="i">
            <img
              :src="`${ossPrefix}${v.cover}`"
              class="home-img"
              @click="toProDetails(v.productId)"
            />
            <div class="home-img-text" :class="getContentColor2(i)">
              {{ v.productName }}
            </div>
            <div
              v-if="v.pdfFileUrl"
              class="home-img-btn black-hover-border"
              @click="toDownload(v.productId)"
            >
              Download Producet PDF <span class="swiper-text">></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part1">
      <div class="part1-item">
        <div class="part1-text">Call Aly Home</div>
        <div class="part1-phone">TEL：0086-574-62165998</div>
        <div class="part1-phone">E-mail：sale@yyaly.com</div>
        <div class="part1-text">Mon - Fri 9am - 5pm</div>
      </div>
      <div class="part1-item">
        <div class="part1-text">Contact Aly Home</div>
        <div class="part1-text">Leave a message and we will be in touch</div>
        <div class="part1-btn btn black-hover-border" @click="toContactUs()">
          CONTACT FORM<span class="swiper-text">></span>
        </div>
      </div>
    </div>

    <div class="part2">
      <div class="part-left">
        <div class="part-title">
          <div class="part-line"></div>
          <div class="part-name">A Personalised Service</div>
        </div>
        <div class="part-text">
          We understand that all clients are different, with different visions,
          budgets and requirements.As such, our Customer Service team will deal
          with you on an individual basis, offering a truly personalised service
          from start to finish.
        </div>
        <div class="part-btn btn black-hover" @click="toContactUs()">
          CONTACT FORM<span class="swiper-text">></span>
        </div>
      </div>
      <div class="part-right">
        <img
          class="part-right-img"
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/lab_icon_year@2x.png"
        />
      </div>
    </div>
    <div class="part3">
      <div class="part-right">
        <img
          class="part-right-img"
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/home_bg_manu@2x.png"
        />
      </div>
      <div class="part-left">
        <div class="part-title">
          <div class="part-line"></div>
          <div class="part-name">Made In The CN</div>
        </div>
        <div class="part-text">
          All products are made to measure in-house by our talented production
          teams. We take a great deal of pride in delivering the finest interior
          products, handcrafting every item and checking it against thorough
          quality control criteria before despatch.
        </div>
        <div class="part-btn btn black-hover" @click="toContactUs()">
          CONTACT FORM<span class="swiper-text">></span>
        </div>
      </div>
    </div>
    <el-dialog
      title="Fill in email address"
      :visible.sync="emailVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="email-tips">
        Please provide your real email address so that we can send you the
        product image
      </div>

      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="email">
          <el-input
            v-model="form.email"
            placeholder="Please enter your email address"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">Submit</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  usProductList,
  usVisitLogInsert,
  getIpAddress,
  usApplyAdd,
} from "@/api/home.js";
import "@/style/home.css";

export default {
  name: "Home",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      emailVisible: false,
      productId: "",
      form: {
        email: "",
      },
      ipAddress: "",
      rules: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email format",
            trigger: "blur",
          },
        ],
      },
      carouselItems: [
        {
          imageUrl:
            "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/home_bg1.jpg",
        },
        {
          imageUrl:
            "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/home_bg2.jpg",
        },
        {
          imageUrl:
            "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/home_bg3.jpg",
        },
        {
          imageUrl:
            "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/home_bg4.jpg",
        },
      ],
      proList: [],
      proList1: [],
      proList2: [],
    };
  },
  computed: {
    getContentColor1() {
      return (index) => (index % 2 == 0 ? "home-color1" : "home-color2");
    },
    getContentColor2() {
      return (index) => (index % 2 != 0 ? "home-color1" : "home-color2");
    },
  },
  activated() {
    // console.log("activated");
    this.addVisitLogInsert();
    this.getData();
  },
  methods: {
    getData() {
      getIpAddress().then((res) => {
        if (res.code == 1) {
          this.ipAddress = res.data.ipAddress;
        } else {
          this.ipAddress = "";
          this.$message.error(res.message);
        }
      });
      usProductList().then((res) => {
        // console.log(res);
        if (res.code == 1) {
          let list = res.data.dataList;
          this.proList = [...list];
          this.proList1 = [];
          this.proList2 = [];
          list.forEach((v, i) => {
            if (i % 2 == 0) {
              this.proList1.push(v);
            } else {
              this.proList2.push(v);
            }
          });
        } else {
          this.proList = [];
          this.proList1 = [];
          this.proList2 = [];
          this.$message.error(res.message);
        }
      });
    },
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "Home";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
    toDownload(id) {
      // console.log(id, ">>>>");
      this.productId = id;
      this.form = {
        email: "",
      };
      this.emailVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交表单逻辑
          let obj = {
            productId: this.productId,
            mailbox: this.form.email,
            ipAddress: this.ipAddress,
          };
          usApplyAdd(obj).then((res) => {
            this.emailVisible = false;
            if (res.code == 1) {
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    toContactUs() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "ContactUs",
      });
    },
    toOurStory() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "OurStory",
      });
    },
    toProDetails(id) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "ProDetails",
        query: {
          productId: id,
        },
      });
    },
  },
};
</script>
<style scoped>
.swiper >>> .el-carousel__indicators--horizontal {
  position: absolute;
  bottom: 5px;
  left: 880px;
  height: 26px;
  max-width: 150px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  z-index: 15 !important;
}
.swiper
  >>> .el-carousel__indicators--horizontal
  .el-carousel__indicator--horizontal {
  display: flex;
  align-items: center;
}
.swiper >>> .el-carousel__button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.swiper >>> .el-carousel__arrow {
  width: 60px;
  height: 60px;
  bottom: 10px;
}
.swiper >>> .el-carousel__arrow i {
  font-size: 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.home_carousel {
  width: 1920px !important;
  height: 578px !important;
  z-index: 5 !important;
  overflow-y: hidden;
}
.home_carousel >>> .el-carousel__container {
  width: 1920px !important;
  height: 576px !important;
  z-index: 9 !important;
}
</style>
